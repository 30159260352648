.view {
    display: flex;
    align-items: baseline;
}

.wrapper button {
    margin-left: 0.5em;
}

.rightAlign {
    text-align: right;
}

.editorRow button {
    margin-bottom: 0.5em;
}
