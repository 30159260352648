.figureWrapper {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    margin-bottom: 0.5em;
}

.figureImage {
    flex-basis: 0;
    flex-grow: 1;
}

.figureImage button {
    border: none;
}

.figureCaption {
    flex-basis: 0;
    flex-grow: 1;
}

.muted {
    color: #777;
}

.fileInput {
    position: absolute;
    left: -1000px;
    top: -1000px;
    visibility: hidden;
}
